import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Configs,
  GetTeamsByUserNameInput,
  Metadata,
  MoveTeamWeblabsInput,
  Team,
  TeamSettings,
} from "../models/API";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Box,
  Tooltip,
  DialogContentText,
} from "@mui/material";
import { getTeamsByUserName } from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { moveTeamWeblabs } from "../graphql/mutations";
import MessageModal from "./MessageModal";
import InfoModal from "./InfoModal";
import ErrorModal from "./ErrorModal";

interface AdminDialogProps {
  isOpen: boolean;
  onClose: () => void;
  metaData: Metadata;
  configs: Configs;
  initialData: TeamSettings;
  currentUserName: string;
  currentUserId: number;
  currentTeamId: number;
  ids: number[];
}

const AdminDialog: React.FC<AdminDialogProps> = ({
  isOpen,
  onClose,
  metaData,
  configs,
  initialData,
  currentUserName,
  currentUserId,
  currentTeamId,
  ids,
}) => {
  const client = generateClient();
  const [teams, setTeams] = useState<Team[]>([]);
  const [teamId, setTeamId] = useState<number | null>(null);
  const [message, setMessage] = useState<string | null>(null);
  const [infoMessage, setInfoMessage] = useState<string | null>(null);
  const [error, setError] = useState<any>(null);

  const getTeams = async () => {
    try {
      const input: GetTeamsByUserNameInput = {
        user_name: currentUserName,
      };
      const result = await client.graphql({
        query: getTeamsByUserName,
        variables: {
          input: input,
        },
      });
      const teamsData = result.data.getTeamsByUserName;
      const destinationTeams = teamsData.filter(
        (item) => item.id !== currentTeamId
      );
      setTeams(destinationTeams);
    } catch (err) {
      console.error(err);
    }
  };

  const moveWeblabs = async () => {
    try {
      if (teamId === null) {
        setError("Please select a destination team!!!");
        return; // didn't select anything, return
      }
      setMessage("Please wait, moving weblabs...");
      const input: MoveTeamWeblabsInput = {
        dest_team_id: teamId,
        src_team_id: currentTeamId,
        ids: ids.join(","),
        last_updated_by: currentUserId,
      };
      const result = await client.graphql({
        query: moveTeamWeblabs,
        variables: {
          input: input,
        },
      });
      const data = result.data.moveTeamWeblabs;
      console.log(data.count);
      setMessage(null);
      setInfoMessage(
        "Moved " + data.count + " weblabs to " + configs.teamsDict[teamId]
      );
    } catch (err) {
      console.error(err);
    }
  };

  const handleClose = () => {
    setTeamId(null);
    onClose();
  };

  useEffect(() => {
    getTeams();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentTeamId]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Move Weblabs</DialogTitle>
      <DialogContent>
        <DialogContentText>
          There are {ids.length} weblabs in the current data grid.
        </DialogContentText>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box alignItems={"center"}>
              <FormControl fullWidth={true} margin="dense">
                <InputLabel id="team-select-label">
                  Select Destination Team*
                </InputLabel>
                <Select
                  value={teamId}
                  label="Destination Team"
                  onChange={(e) => setTeamId(Number(e.target.value))}
                  variant={"outlined"}
                >
                  {teams.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Tooltip
                title="Move the data grid weblabs to the destination team"
                placement={"top"}
              >
                <button onClick={moveWeblabs}>Move Weblabs</button>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
      <ErrorModal error={error} onClose={() => setError(null)} />
      <MessageModal message={message} />
      <InfoModal
        title={"Moved successfully!"}
        info={infoMessage}
        onClose={() => setInfoMessage(null)}
      />
    </Dialog>
  );
};

export default AdminDialog;
