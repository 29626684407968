import { useEffect, useState, createContext } from "react";
import { generateClient } from "aws-amplify/api";
import {CreateUserInput, GetTeamsByUserNameInput, Team, User} from "./models/API";
import MainPage from "./components/MainPage";
import {getTeamsByUserName} from "./graphql/queries";
import {
  AuthSession,
  AuthUser,
  fetchAuthSession,
  getCurrentUser,
  signInWithRedirect,
} from "aws-amplify/auth";

import {createUser} from "./graphql/mutations";

const setPostAuthRedirect = () => {
  const pathname = window.location.pathname;
  window.sessionStorage.setItem("postAuthRedirect", pathname);
};

const App = () => {
  const [user, setUser] = useState("");
  const [isError, setIsError] = useState<boolean>(false);
  const [defaultTeamId, setdefaultTeamId] = useState(-1);
  const [teamConfig, setTeamConfig] = useState<Team>( {
    __typename: "Team",
    id: -1,
    name: "",
    custom_attributes: "",
  });
  const [userConfig, setUserConfig] = useState<User | null>(null);
  const [currentUser, setCurrentUser] = useState<AuthUser | null>(null);
  const [authSession, setAuthSession] = useState<AuthSession | null>(null);
  const [loading, setLoading] = useState(true);

  const client = generateClient();

  const isMidwayError = () => {
    return isError;
  };

  const isDev = () => {
    return window.location.href === "http://localhost:3000/";
  };

  const createMainPage = () => {
    if (defaultTeamId === -1) return <div>Loading...</div>
    return <MainPage currentUserName={user} userConfig={userConfig} defaultTeamId={defaultTeamId} defaultTeamConfig={teamConfig} />
  };

  const getTeams = async (currentUser: string) => {
    let team: Team =
      {
        __typename: "Team",
        id: -1,
        name: "",
        custom_attributes: "",
      };
    if (currentUser === "") return team;

    try {
      const createUserInput: CreateUserInput = {
        user_name: currentUser,
      };
      const user = await client.graphql({
        query: createUser,
        variables: {
          input: createUserInput,
        },
      });
      setUserConfig(user.data.createUser);
      const input: GetTeamsByUserNameInput = {
        user_name: currentUser,
      };
      const result = await client.graphql({
        query: getTeamsByUserName,
        variables: {
          input: input,
        },
      });
      const teamsData = result.data.getTeamsByUserName;
      if (teamsData.length > 0)
        return teamsData[0];
    } catch (err) {
      console.error(err);
    }
    team.id = 0;
    return team; // default team id that doesn't exist
  };

  async function checkUser() {
    try {
      const authSession = await fetchAuthSession({ forceRefresh: true });
      const currentUser = await getCurrentUser();
      setCurrentUser(currentUser);
      setUser(currentUser.username.split("_")[1]);
      setAuthSession(authSession);
    } catch (error) {
      console.error("Authentication failed", error);
      setCurrentUser(null);
      setAuthSession(null);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user === "") {
      checkUser().then();
    } else {
      getTeams(user).then( team => {
        setdefaultTeamId(team.id);
        setTeamConfig(team);
      });
    }
  }, [user]);

  if (loading) {
    return ( createMainPage() );
  }
  if (!currentUser) {
    try {
      setPostAuthRedirect();
      signInWithRedirect({
        provider: {
          custom: "AmazonFederate",
        },
      });
    } catch (error) {
      console.error("Authentication failed", error);
    }
  }
  return ( createMainPage() );
}

export default App;
